import { render, staticRenderFns } from "./SelectBox.vue?vue&type=template&id=4480dd35&scoped=true&"
import script from "./SelectBox.vue?vue&type=script&lang=js&"
export * from "./SelectBox.vue?vue&type=script&lang=js&"
import style0 from "./SelectBox.vue?vue&type=style&index=0&id=4480dd35&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4480dd35",
  null
  
)

export default component.exports