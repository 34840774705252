<template>
  <v-form
    v-if="$parent.damagereport.id"
    ref="form"
    class="damagereport"
    data-app
  >
   <section class="form-section">
      <fieldset>
        <legend>Schadenserfassung</legend>
        <v-layout wrap class="gap-row">
          <v-flex xs6>
            <label for="schadensDatum">
              Datum<span class="required-mark" />
              <v-text-field
                id="schadensDatum"
                v-model="schadensDatum"
                :disabled="abgeschlossen"
                :max="datumHeute"
                :rules="(validating) ? formrules.schadensDatum : []"
                type="date"
                required
              ></v-text-field>
            </label>
          </v-flex>
          <v-flex xs6>
            <label for="schadensUhrzeit">
              Uhrzeit<span class="required-mark" />
              <div class="input-wrapper">
                <input
                  id="schadensUhrzeit"
                  v-model="schadensUhrzeit"
                  :disabled="abgeschlossen"
                  type="time"
                />
              </div>
            </label>
          </v-flex>
          <v-flex xs12>
            <input-radio
              v-model="$parent.damagereport.privatfahrt"
              :options="{
                Privatfahrt: true,
                Dienstfahrt: false,
              }"
              :disabled="abgeschlossen"
              :validating="validating"
              :inputrules="formrules.privatfahrt"
              :required="true"
              data-ref="privatfahrt"
              ref="privatfahrt"
              label="Privatfahrt"
            />
          </v-flex>
          <v-flex xs12>
            <label for="unfallhergang">
              Unfallhergang<span class="required-mark"/>
              <v-textarea
                id="unfallhergang"
                v-model="$parent.damagereport.unfallhergang"
                :disabled="abgeschlossen"
                :rules="(validating) ? formrules.unfallhergang : []"
                rows="3"
                required
                auto-grow
              ></v-textarea>
            </label>
          </v-flex>
        </v-layout>
        <h3>Schadensort</h3>
        <input-address
          v-model="$parent.damagereport"
          :disabled="abgeschlossen"
          :validating="validating"
          :inputrules="formrules.schadensort"
          :required="true"
        />
      </fieldset>
    </section>

    <section class="form-section">
      <fieldset>
        <legend>Firmenwagen</legend>
        <v-layout v-if="showFahrzeugSuche" wrap class="gap-row">
          <v-flex xs12>
            <v-btn
              @click.stop="$refs.dialogFahrzeugsuche.openDialog()"
              :disabled="abgeschlossen"
              color="info"
              style="float: right"
            >
              fahrzeugsuche
            </v-btn>
          </v-flex>
        </v-layout>

        <license-number
          v-model="kennzeichenFirmenwagen"
          :disabled="abgeschlossen"
          :validating="validating"
          :required="true"
        />
        <v-layout wrap class="gap-row">
          <v-flex xs6>
            <label for="firmenwagenHersteller">
              Hersteller<span class="required-mark" />
              <select-box
                id="firmenwagenHersteller"
                v-model="firmenwagenHersteller"
                :key="herstellerKey"
                :options="hersteller"
                :disabled="abgeschlossen"
                :validating="validating"
                :inputrules="formrules.selectHersteller"
                data-ref="firmenwagenHersteller"
                ref="firmenwagenHersteller"
              />
            </label>
          </v-flex>
          <v-flex xs6>
            <label
              for="firmenwagenAndererHersteller"
              v-if="
              $parent.damagereport.fw_hersteller === 'Anderer'
              || (
                !hersteller.find(item => item === $parent.damagereport.fw_hersteller)
                && $parent.damagereport.fw_hersteller.length
              )
            ">
              Anderer Hersteller<span class="required-mark" />
              <v-text-field
                id="firmenwagenAndererHersteller"
                v-model="firmenwagenAndererHersteller"
                :rules="(validating) ? formrules.andererHersteller : []"
                :disabled="abgeschlossen"
              ></v-text-field>
            </label>
          </v-flex>
        </v-layout>

        <v-layout wrap class="gap-row">
          <v-flex xs6>
            <label for="fw_modell">
              Modell<span class="required-mark" />
              <v-text-field
                id="fw_modell"
                v-model="$parent.damagereport.fw_modell"
                :disabled="abgeschlossen"
                :rules="(validating) ? formrules.firmenwagenModell : []"
                type="text"
                required
              ></v-text-field>
            </label>
          </v-flex>
        </v-layout>

        <v-layout wrap class="gap-row">
          <v-flex xs6>
            <label for="firmenwagenErstzulassungsDatum">
              Erstzulassung<span class="required-mark" />
              <v-text-field
                id="firmenwagenErstzulassungsDatum"
                v-model="firmenwagenErstzulassungsDatum"
                :disabled="abgeschlossen"
                :rules="(validating) ? formrules.erstzulassung : []"
                :max="datumHeute"
                type="date"
                required
              ></v-text-field>
            </label>
          </v-flex>
          <v-flex xs6>
            <label for="kilometerStand">
              KM-Stand<span class="required-mark" />
              <v-text-field
                id="kilometerStand"
                v-model="kilometerStand"
                :disabled="abgeschlossen"
                :rules="(validating) ? formrules.kilometerStand : []"
                type="text"
                inputmode="decimal"
              ></v-text-field>
            </label>
          </v-flex>
        </v-layout>
        <v-layout wrap class="gap-row">
          <v-flex xs9>
            <h3>Gespann / Anhänger</h3>
          </v-flex>
          <v-flex xs3 class="text-xs-right">
            <el-switch
              v-model="$parent.damagereport.fw_anhaenger"
              :disabled="abgeschlossen"
              active-color="#6eb233"
            />
          </v-flex>
        </v-layout>
        <v-layout
          v-if="$parent.damagereport.fw_anhaenger"
          wrap class="gap-row">
          <v-flex xs12>
            <input-radio
              v-model="$parent.damagereport.fw_miet_anhaenger"
              :options="{
                Ja: true,
                Nein: false,
              }"
              :disabled="abgeschlossen"
              label="Mietanhänger"
            />
          </v-flex>
          <v-flex xs4>
            <label for="fw_kennzeichen_anhaenger1">
              Kennzeichen-Anhänger<span class="required-mark" />
              <v-text-field
                id="fw_kennzeichen_anhaenger1"
                v-model="$parent.damagereport.fw_kennzeichen_anhaenger1"
                :disabled="abgeschlossen"
                :rules="(validating) ? formrules.kennzeichen : []"
                placeholder="F"
              ></v-text-field>
            </label>
          </v-flex>
          <v-flex xs4>
            <label for="fw_kennzeichen_anhaenger2">
              <span class="is-hidden">Kennzeichen-Anhänger</span>
              <span class="required-mark -hidden" />
              <v-text-field
                id="fw_kennzeichen_anhaenger2"
                v-model="$parent.damagereport.fw_kennzeichen_anhaenger2"
                :disabled="abgeschlossen"
                :rules="(validating) ? formrules.kennzeichen : []"
                placeholder="WS"
              ></v-text-field>
            </label>
          </v-flex>
          <v-flex xs4>
            <label for="fw_kennzeichen_anhaenger3">
              <span class="is-hidden">Kennzeichen-Anhänger</span>
              <span class="required-mark -hidden" />
              <v-text-field
                id="fw_kennzeichen_anhaenger3"
                v-model="$parent.damagereport.fw_kennzeichen_anhaenger3"
                :disabled="abgeschlossen"
                :rules="(validating) ? formrules.kennzeichenNummer : []"
                placeholder="1234E"
              ></v-text-field>
            </label>
          </v-flex>
        </v-layout>
      </fieldset>
    </section>

    <section class="form-section">
      <fieldset>
        <legend>Schäden</legend>
        <v-layout wrap class="gap-row">
          <v-flex xs12>
            <input-radio
              v-model="$parent.damagereport.sb_selber_verletzt"
              :options="{
                Ja: true,
                Nein: false,
              }"
              :disabled="abgeschlossen"
              :validating="validating"
              :inputrules="formrules.selberVerletzt"
              :required="true"
              data-ref="selberVerletzt"
              ref="selberVerletzt"
              label="Ich bin selber verletzt"
            />
          </v-flex>
          <v-flex xs12>
            <label for="sb_schaeden_am_kfz">
              Schäden am Wisag-Kfz<span class="required-mark" />
              <v-textarea
                id="sb_schaeden_am_kfz"
                v-model="$parent.damagereport.sb_schaeden_am_kfz"
                :disabled="abgeschlossen"
                :rules="(validating) ? formrules.schaedenAmKfz : []"
                rows="3"
                required
                auto-grow
              ></v-textarea>
            </label>
          </v-flex>
          <v-flex xs9>
            <h3>Fahrzeug kann besichtigt werden</h3>
          </v-flex>
          <v-flex xs3 class="text-xs-right">
            <el-switch
              v-model="$parent.damagereport.sb_fahrzeugbesichtigung_moeglich"
              :disabled="abgeschlossen"
              active-color="#6eb233"
            />
          </v-flex>
        </v-layout>
        <v-layout
          v-if="$parent.damagereport.sb_fahrzeugbesichtigung_moeglich"
          wrap class="gap-row"
        >
          <v-flex xs8 md9>
            <label for="sb_firma">
              Firma<span class="required-mark" />
              <v-text-field
                id="sb_firma"
                v-model="$parent.damagereport.sb_firma"
                :disabled="abgeschlossen"
                :rules="(validating) ? formrules.firma : []"
                required
              ></v-text-field>
            </label>
          </v-flex>
          <v-flex xs12>
            <input-address
              v-model="addresseSchadenbeschreibung"
              :disabled="abgeschlossen"
              :inputrules="formrules.besichtigungsOrt"
              :validating="validating"
              :required="true"
            />
          </v-flex>
          <v-flex xs12>
            <label for="sb_telefon">
              Telefon
              <v-text-field
                id="sb_telefon"
                v-model="$parent.damagereport.sb_telefon"
                :disabled="abgeschlossen"
              ></v-text-field>
            </label>
          </v-flex>
        </v-layout>
      </fieldset>
    </section>

    <section class="form-section">
      <fieldset>
        <legend>Fahrer</legend>
        <v-layout wrap class="gap-row">
          <v-flex xs9>
            <h3>Jemand anderes ist gefahren</h3>
          </v-flex>
          <v-flex xs3 class="text-xs-right">
            <el-switch
              v-model="$parent.damagereport.fahrer_abweichender_fahrer"
              :disabled="abgeschlossen"
              active-color="#6eb233"
            />
          </v-flex>
        </v-layout>
        <v-layout
          v-if="$parent.damagereport.fahrer_abweichender_fahrer"
          wrap class="gap-row"
        >
          <v-flex xs12>
            <input-radio
              v-model="$parent.damagereport.fahrer_anrede"
              :options="{
                Herr: 'Herr',
                Frau: 'Frau',
              }"
              :disabled="abgeschlossen"
              :validating="validating"
              :inputrules="formrules.fahrerAnrede"
              data-ref="fahrerAnrede"
              ref="fahrerAnrede"
              label="Anrede"
            />
          </v-flex>
          <v-flex xs6>
            <label for="fahrer_vorname">
              Vorname<span class="required-mark" />
              <v-text-field
                id="fahrer_vorname"
                v-model="$parent.damagereport.fahrer_vorname"
                :disabled="abgeschlossen"
                :rules="(validating) ? formrules.fahrerVorname : []"
              ></v-text-field>
            </label>
          </v-flex>
          <v-flex xs6>
            <label for="fahrer_nachname">
              Nachname<span class="required-mark" />
              <v-text-field
                id="fahrer_nachname"
                v-model="$parent.damagereport.fahrer_nachname"
                :disabled="abgeschlossen"
                :rules="(validating) ? formrules.fahrerNachname : []"
              ></v-text-field>
            </label>
          </v-flex>
          <v-flex xs12>
            <input-address
              v-model="addresseFahrer"
              :disabled="abgeschlossen"
              :inputrules="formrules.fahrerAdresse"
              :validating="validating"
              :required="true"
            />
          </v-flex>
          <v-flex xs6>
            <label for="fahrerGeburtsDatum">
              Geburtsdatum<span class="required-mark" />
              <v-text-field
                id="fahrerGeburtsDatum"
                v-model="fahrerGeburtsDatum"
                :disabled="abgeschlossen"
                :rules="(validating) ? formrules.fahrerGeburtsDatum : []"
                :max="datumHeute"
                type="date"
                required
              ></v-text-field>
            </label>
          </v-flex>
        </v-layout>
        <v-layout wrap class="gap-row">
          <v-flex xs12>
            <input-radio
              v-model="$parent.damagereport.fahrer_fuehrerschein"
              :options="{
                Ja: true,
                Nein: false,
              }"
              :disabled="abgeschlossen"
              :validating="validating"
              :inputrules="formrules.fahrerFuehrerschein"
              :required="true"
              data-ref="fahrerFuehrerschein"
              ref="fahrerFuehrerschein"
              label="Führerschein vorhanden"
            />
          </v-flex>
          <v-flex xs12>
            <input-radio
              v-model="$parent.damagereport.fahrer_alkoholgenuss"
              :options="{
                Ja: true,
                Nein: false,
              }"
              :disabled="abgeschlossen"
              :validating="validating"
              :inputrules="formrules.fahrerAlkohol"
              :required="true"
              data-ref="fahrerAlkohol"
              ref="fahrerAlkohol"
              label="Alkoholgenuss"
            />
          </v-flex>
        </v-layout>
      </fieldset>
    </section>

    <section class="form-section">
      <fieldset>
        <v-layout wrap class="gap-row">
          <v-flex xs9>
            <br><br>
            <legend class="text-xs-left">Unfallgegner</legend>
          </v-flex>
          <v-flex xs3 class="text-xs-right">
            <br><br>
            <el-switch
              v-model="$parent.damagereport.fs_fremd_schaden"
              :disabled="abgeschlossen"
              active-color="#6eb233"
            />
          </v-flex>
        </v-layout>
        <template
          v-if="$parent.damagereport.fs_fremd_schaden"
        >
          <v-layout wrap class="gap-row">
            <v-flex xs9>
              <h3>Kfz-Schaden</h3>
            </v-flex>
            <v-flex xs3 class="text-xs-right">
              <el-switch
                v-model="$parent.damagereport.fs_schaden_am_kfz"
                :disabled="abgeschlossen"
                active-color="#6eb233"
              />
            </v-flex>
          </v-layout>
          <license-number
            v-if="$parent.damagereport.fs_schaden_am_kfz"
            v-model="kennzeichenFremdschaden"
            :disabled="abgeschlossen"
            :validating="validating"
            :fremd-kennzeichen="true"
          />
          <v-layout
            v-if="$parent.damagereport.fs_schaden_am_kfz"
            wrap class="gap-row"
          >
            <v-flex xs6>
              <label for="fs_hersteller">
                Hersteller
                <v-text-field
                  id="fs_hersteller"
                  v-model="$parent.damagereport.fs_hersteller"
                  :disabled="abgeschlossen"
                ></v-text-field>
              </label>
            </v-flex>
            <v-flex xs6>
              <label for="fs_typ">
                Modell
                <v-text-field
                  id="fs_typ"
                  v-model="$parent.damagereport.fs_typ"
                  :disabled="abgeschlossen"
                ></v-text-field>
              </label>
            </v-flex>
            <v-flex xs12>
              <label for="fs_schaeden_am_kfz">
                Schäden am Kfz
                <v-textarea
                  id="fs_schaeden_am_kfz"
                  v-model="$parent.damagereport.fs_schaeden_am_kfz"
                  :disabled="abgeschlossen"
                  rows="3"
                  required
                  auto-grow
                ></v-textarea>
              </label>
            </v-flex>
            <v-flex xs12>
              <input-radio
                v-model="$parent.damagereport.fs_fahrer_gleich_halter"
                :options="{
                  Ja: true,
                  Nein: false,
                }"
                :disabled="abgeschlossen"
                label="Ist der Fahrer der Halter?"
              />
            </v-flex>
            <v-flex xs12>
              <input-radio
                v-model="$parent.damagereport.fs_fuehrerschein"
                :options="{
                  Ja: true,
                  Nein: false,
                }"
                :disabled="abgeschlossen"
                label="Hat der Fahrer einen Führerschein"
              />
            </v-flex>
          </v-layout>
          <v-layout wrap class="gap-row">
            <v-flex xs12>
              <p>
                <b>Daten zum geschädigten Fahrer / zur geschädigten Person.<br>
                Bei Sachschäden geben Sie diese bitte im Feld "Vorname" an.</b>
              </p>
            </v-flex>
            <v-flex xs12>
              <input-radio
                v-model="$parent.damagereport.fs_anrede"
                :options="{
                  Herr: 'Herr',
                  Frau: 'Frau',
                }"
                :disabled="abgeschlossen"
                label="Anrede"
              />
            </v-flex>
            <v-flex xs6>
              <label for="fs_vorname">
                Vorname<span class="required-mark" />
                <v-text-field
                  id="fs_vorname"
                  v-model="$parent.damagereport.fs_vorname"
                  :disabled="abgeschlossen"
                  :rules="(validating) ? formrules.fremdschadenVorname : []"
                ></v-text-field>
              </label>
            </v-flex>
            <v-flex xs6>
              <label for="fs_nachname">
                Nachname<span class="required-mark" />
                <v-text-field
                  id="fs_nachname"
                  v-model="$parent.damagereport.fs_nachname"
                  :disabled="abgeschlossen"
                  :rules="(validating) ? formrules.fremdschadenNachname : []"
                ></v-text-field>
              </label>
            </v-flex>
            <v-flex xs12>
              <input-address
                v-model="addresseFremdschaden"
                :disabled="abgeschlossen"
              />
            </v-flex>
            <v-flex xs12>
              <label for="fs_telefon">
                Telefon
                <v-text-field
                  id="fs_telefon"
                  v-model="$parent.damagereport.fs_telefon"
                  :disabled="abgeschlossen"
                ></v-text-field>
              </label>
            </v-flex>
            <v-flex xs12>
              <input-radio
                v-model="$parent.damagereport.fs_personenschaden"
                :options="{
                  Ja: true,
                  Nein: false,
                }"
                :disabled="abgeschlossen"
                label="Personenschaden"
              />
            </v-flex>
            <v-flex xs12>
              <label for="fs_versicherung">
                Versicherung
                <v-textarea
                  id="fs_versicherung"
                  v-model="$parent.damagereport.fs_versicherung"
                  :disabled="abgeschlossen"
                  :rules="(validating) ? formrules.fremdschadenVersicherung : []"
                  rows="3"
                  auto-grow
                ></v-textarea>
              </label>
            </v-flex>
            <v-flex xs12>
              <label for="fs_versicherungsnummer">
                Versicherungsnummer
                <v-text-field
                  id="fs_versicherungsnummer"
                  v-model="$parent.damagereport.fs_versicherungsnummer"
                  :disabled="abgeschlossen"
                ></v-text-field>
              </label>
            </v-flex>
            <v-flex xs12>
              <b>Sonstige Beteiligte</b><br>
              <label for="fs_sonstige_beteiligte">
                Namen, Kfz-Kennzeichen
                <v-textarea
                  id="fs_sonstige_beteiligte"
                  v-model="$parent.damagereport.fs_sonstige_beteiligte"
                  :disabled="abgeschlossen"
                  rows="3"
                  required
                  auto-grow
                ></v-textarea>
              </label>
            </v-flex>
          </v-layout>
        </template>
      </fieldset>
    </section>

    <section class="form-section">
      <fieldset>
        <legend>Zeugen / Polizei</legend>
        <v-layout wrap class="gap-row">
          <v-flex xs9>
            <h3>Zeugen anwesend?</h3>
          </v-flex>
          <v-flex xs3 class="text-xs-right">
            <el-switch
              v-model="$parent.damagereport.zp_zeugen_anwesend"
              :disabled="abgeschlossen"
              active-color="#6eb233"
            />
          </v-flex>
        </v-layout>
        <v-layout
          v-if="$parent.damagereport.zp_zeugen_anwesend"
          wrap class="gap-row"
        >
          <v-flex xs12>
            <input-radio
              v-model="$parent.damagereport.zp_anrede"
              :options="{
                Herr: 'Herr',
                Frau: 'Frau',
              }"
              :disabled="abgeschlossen"
              label="Anrede"
            />
          </v-flex>
          <v-flex xs6>
            <label for="zp_vorname">
              Vorname
              <v-text-field
                id="zp_vorname"
                v-model="$parent.damagereport.zp_vorname"
                :disabled="abgeschlossen"
              ></v-text-field>
            </label>
          </v-flex>
          <v-flex xs6>
            <label for="zp_nachname">
              Nachname
              <v-text-field
                id="zp_nachname"
                v-model="$parent.damagereport.zp_nachname"
                :disabled="abgeschlossen"
              ></v-text-field>
            </label>
          </v-flex>
          <v-flex xs12>
            <input-address
              v-model="addresseZeugenPolizei"
              :disabled="abgeschlossen"
            />
          </v-flex>
          <v-flex xs12>
            <label for="zp_telefon">
              Telefon
              <v-text-field
                id="zp_telefon"
                v-model="$parent.damagereport.zp_telefon"
                :disabled="abgeschlossen"
              ></v-text-field>
            </label>
          </v-flex>
        </v-layout>
        <v-layout wrap class="gap-row">
          <v-flex xs9>
            <h3>Polizei informiert?</h3>
          </v-flex>
          <v-flex xs3 class="text-xs-right">
            <el-switch
              v-model="$parent.damagereport.zp_polizei_informiert"
              :disabled="abgeschlossen"
              active-color="#6eb233"
              @change="$nextTick(() => validateForm())"
            />
          </v-flex>
        </v-layout>
        <v-layout
          v-if="$parent.damagereport.zp_polizei_informiert"
          wrap class="gap-row"
        >
          <v-flex xs6>
            <label for="zp_dienststelle">
              Dienststelle
              <v-text-field
                id="zp_dienststelle"
                v-model="$parent.damagereport.zp_dienststelle"
                :disabled="abgeschlossen"
              ></v-text-field>
            </label>
          </v-flex>
          <v-flex xs6>
            <label for="zp_tagebuchnummer">
              Tagebuchnummer
              <v-text-field
                id="zp_tagebuchnummer"
                v-model="$parent.damagereport.zp_tagebuchnummer"
                :disabled="abgeschlossen"
                :rules="(validating) ? formrules.tagebuchNummer : []"
              ></v-text-field>
            </label>
          </v-flex>
          <v-flex xs12>
            <input-radio
              v-model="$parent.damagereport.zp_verwarnung_erteilt"
              :options="{
                Ja: true,
                Nein: false,
              }"
              :disabled="abgeschlossen"
              :validating="validating"
              :inputrules="formrules.polizeiVerwarnung"
              :required="true"
              data-ref="polizeiVerwarnung"
              ref="polizeiVerwarnung"
              label="Gebührenpflichtige Verwarnung?"
            />
          </v-flex>
        </v-layout>
      </fieldset>
    </section>

    <section class="form-section" v-if="jwt">
      <fieldset>
        <legend>Fotos (max. 15)</legend>
        <v-layout wrap class="gap-row">
          <v-flex xs12>
            <file-pond
              name="filepond-input"
              :disabled="abgeschlossen"
              ref="pond"
              label-idle="Bilder hinzufügen..."
              max-files="15"
              accepted-file-types="image/jpeg"
              imagePreviewMaxFileSize="4000KB"
              styleItemPanelAspectRatio="0.56"
              imagePreviewTransparencyIndicator="#6db133"
              :allow-multiple="true"
              :server="serverConfig"
              :files="formImages"
            />
          </v-flex>
        </v-layout>
      </fieldset>
    </section>
    <section class="form-buttons">
      <template v-if="!abgeschlossen">
        <v-btn
          :loading="saving"
          :disabled="saving"
          color="info"
          @click="saveForm(false)"
        >
          speichern
        </v-btn>
        <v-btn
          :loading="saving"
          :disabled="saving"
          color="success"
          @click="completeForm"
        >
          abschicken
        </v-btn>
        <span v-if="validating && !formIsValid" class="error-box">
          Die Meldung kann nur abgeschlossen werden, wenn alle Pflichtfelder gefüllt sind.
        </span>
        <span v-if="formIsInvalidServer" class="error-box">
          Die Formulardaten konnten auf dem Server nicht erfolgreich validiert werden.
          <span v-if="formIsInvalidServerValidationErrors">
            Fehlergrund: {{formIsInvalidServerValidationErrors}}.
          </span>
          Bei Fragen geben Sie bitte diese ID "{{formIsInvalidServerRequestID}}" an.
        </span>
        <v-btn
          :loading="deleting"
          :disabled="deleting"
          color="error"
          @click.stop="$refs.dialogFormDelete.openDialog()"
        >
          löschen
        </v-btn>
      </template>
      <v-btn
        color="info"
        @click="$router.push('/home')"
      >
        Abbrechen / Zur Startseite
      </v-btn>
    </section>
    <div id="fa" /> <!-- anchor to jump on errors -->
    <dialog-fahrzeugsuche ref="dialogFahrzeugsuche"
                           v-if="showFahrzeugSuche"
                           v-model="kennzeichenFirmenwagen"
                           @select="setFoundVehicle($event)"/>
    <dialog-form-delete @confirm="deleteForm" ref="dialogFormDelete" />
    <dialog-form-success ref="dialogFormSuccess" />
    <dialog-form-failure ref="dialogFormFailure" />
  </v-form>
</template>

<script>
import * as moment from 'moment';
import { mapGetters } from 'vuex';
import 'filepond-polyfill';
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';

import formrules from '@/data/formrules';

import httpResourcesMixin from '@/mixins/httpResources';

import InputAddress from '@/components/InputAddress.vue';
import InputRadio from '@/components/InputRadio.vue';
import LicenseNumber from '@/components/LicenseNumber.vue';
import SelectBox from '@/components/SelectBox.vue';
import DialogFormDelete from '@/components/DialogFormDelete.vue';
import DialogFormSuccess from '@/components/DialogFormSuccess.vue';
import DialogFormFailure from '@/components/DialogFormFailure.vue';
import DialogFahrzeugsuche from '@/components/DialogFahrzeugsuche.vue';

const FilePond = vueFilePond(
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateType,
  FilePondPluginImageTransform,
  FilePondPluginImagePreview,
);

export default {
  name: 'DamagereportForm',

  components: {
    DialogFahrzeugsuche,
    FilePond,
    InputAddress,
    InputRadio,
    LicenseNumber,
    SelectBox,
    DialogFormDelete,
    DialogFormSuccess,
    DialogFormFailure,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  provide() {
    return {
      validateForm: this.validateForm,
    };
  },

  data() {
    return {
      formIsInvalidServer: false,
      formIsInvalidServerRequestID: '',
      formIsInvalidServerValidationErrors: null,
      validating: false,
      saving: false,
      deleting: false,
      imageRequest: null,
      datumHeute: moment().format('YYYY-MM-DD'),
      formrules,
      formImages: [],
      herstellerKey: 0,
      hersteller: [
        {
          value: '',
          title: 'Bitte wählen',
        },
        'Mercedes',
        'Audi',
        'VW',
        'Skoda',
        'Opel',
        'Seat',
        'Anderer',
      ],
      serverConfig: {
        load: {
          url: `${this.httpResources.imageURL}/`,
          headers: {
            Authorization: '',
          },
        },
        revert: (imageFileId, load, error) => {
          if (imageFileId) {
            this.httpResources.image.delete({ id: imageFileId })
              .then(() => load())
              .catch(() => error('image cant delete'));
          }
        },
        remove: (imageFileId, load, error) => {
          this.httpResources.image.delete({ id: imageFileId })
            .then(() => load())
            .catch(() => error('image cant delete'));
        },
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          const formData = new FormData();
          const thisRequest = {};
          formData.append('file', file);
          formData.append('report_id', this.$parent.damagereport.id);
          formData.append('image_type', '1');
          formData.append('file_name', file.name);

          this.$http.post(this.httpResources.imageURL, formData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'cache-control': 'no-cache',
            },
            before: (request) => {
              thisRequest.request = request;
            },
            progress: (e) => {
              progress(e.lengthComputable, e.loaded, e.total);
            },
          })
            .then((response) => {
              if (response.status >= 200 && response.status <= 300) load(response.body);
            })
            .catch(() => {
              thisRequest.request.abort();
              abort();
            });

          return {
            abort: () => {
              thisRequest.request.abort();
              abort();
            },
          };
        },
      },
    };
  },

  /* eslint-disable object-shorthand, func-names */
  computed: {
    kennzeichenFirmenwagen: {
      get: function () {
        return {
          kennzeichen1: this.$parent.damagereport.fw_kennzeichen1,
          kennzeichen2: this.$parent.damagereport.fw_kennzeichen2,
          kennzeichen3: this.$parent.damagereport.fw_kennzeichen3,
        };
      },
      set: function (newValue) {
        this.$parent.damagereport.fw_kennzeichen1 = newValue.kennzeichen1.trim();
        this.$parent.damagereport.fw_kennzeichen2 = newValue.kennzeichen2.trim();
        this.$parent.damagereport.fw_kennzeichen3 = newValue.kennzeichen3.trim();
      },
    },

    kennzeichenFremdschaden: {
      get: function () {
        return {
          kennzeichen1: this.$parent.damagereport.fs_kennzeichen1,
          kennzeichen2: this.$parent.damagereport.fs_kennzeichen2,
          kennzeichen3: this.$parent.damagereport.fs_kennzeichen3,
        };
      },
      set: function (newValue) {
        this.$parent.damagereport.fs_kennzeichen1 = newValue.kennzeichen1;
        this.$parent.damagereport.fs_kennzeichen2 = newValue.kennzeichen2;
        this.$parent.damagereport.fs_kennzeichen3 = newValue.kennzeichen3;
      },
    },

    addresseSchadenbeschreibung: {
      get: function () {
        return {
          strasse: this.$parent.damagereport.sb_strasse,
          hausnummer: this.$parent.damagereport.sb_hausnummer,
          plz: this.$parent.damagereport.sb_plz,
          ort: this.$parent.damagereport.sb_ort,
        };
      },
      set: function (newValue) {
        this.$parent.damagereport.sb_strasse = newValue.strasse;
        this.$parent.damagereport.sb_hausnummer = newValue.hausnummer;
        this.$parent.damagereport.sb_plz = newValue.plz;
        this.$parent.damagereport.sb_ort = newValue.ort;
      },
    },

    addresseFahrer: {
      get: function () {
        return {
          strasse: this.$parent.damagereport.fahrer_strasse,
          hausnummer: this.$parent.damagereport.fahrer_hausnummer,
          plz: this.$parent.damagereport.fahrer_plz,
          ort: this.$parent.damagereport.fahrer_ort,
        };
      },
      set: function (newValue) {
        this.$parent.damagereport.fahrer_strasse = newValue.strasse;
        this.$parent.damagereport.fahrer_hausnummer = newValue.hausnummer;
        this.$parent.damagereport.fahrer_plz = newValue.plz;
        this.$parent.damagereport.fahrer_ort = newValue.ort;
      },
    },

    addresseFremdschaden: {
      get: function () {
        return {
          strasse: this.$parent.damagereport.fs_strasse,
          hausnummer: this.$parent.damagereport.fs_hausnummer,
          plz: this.$parent.damagereport.fs_plz,
          ort: this.$parent.damagereport.fs_ort,
        };
      },
      set: function (newValue) {
        this.$parent.damagereport.fs_strasse = newValue.strasse;
        this.$parent.damagereport.fs_hausnummer = newValue.hausnummer;
        this.$parent.damagereport.fs_plz = newValue.plz;
        this.$parent.damagereport.fs_ort = newValue.ort;
      },
    },

    addresseZeugenPolizei: {
      get: function () {
        return {
          strasse: this.$parent.damagereport.zp_strasse,
          hausnummer: this.$parent.damagereport.zp_hausnummer,
          plz: this.$parent.damagereport.zp_plz,
          ort: this.$parent.damagereport.zp_ort,
        };
      },
      set: function (newValue) {
        this.$parent.damagereport.zp_strasse = newValue.strasse;
        this.$parent.damagereport.zp_hausnummer = newValue.hausnummer;
        this.$parent.damagereport.zp_plz = newValue.plz;
        this.$parent.damagereport.zp_ort = newValue.ort;
      },
    },

    abgeschlossen() {
      return this.$parent.damagereport.schadensmeldung_abgeschlossen;
    },

    schadensDatum: {
      get: function () {
        return moment(this.$parent.damagereport.schadens_datum).format('YYYY-MM-DD');
      },

      set: function (newValue) {
        this.$parent.damagereport.schadens_datum = moment(`${newValue} ${this.schadensUhrzeit}`, 'YYYY-MM-DD HH:mm').utc().toISOString();
      },
    },

    schadensUhrzeit: {
      get: function () {
        return moment(this.$parent.damagereport.schadens_datum).format('HH:mm');
      },

      set: function (newValue) {
        this.$parent.damagereport.schadens_datum = moment(`${this.schadensDatum} ${newValue}`, 'YYYY-MM-DD HH:mm').utc().toISOString();
      },
    },

    firmenwagenErstzulassungsDatum: {
      get: function () {
        const DateObject = moment(this.$parent.damagereport.fw_erstzulassung);
        if (DateObject.format('YYYY') === '0001') return null;

        return DateObject.format('YYYY-MM-DD');
      },

      set: function (newValue) {
        this.$parent.damagereport.fw_erstzulassung = moment(newValue, 'YYYY-MM-DD').utc().toISOString();
      },
    },

    firmenwagenHersteller: {
      get: function () {
        if (
          !this.hersteller.find((item) => item === this.$parent.damagereport.fw_hersteller)
          && this.$parent.damagereport.fw_hersteller.length > 0
        ) {
          return 'Anderer';
        }

        return this.$parent.damagereport.fw_hersteller;
      },

      set: function (newValue) {
        this.$parent.damagereport.fw_hersteller = newValue;
      },
    },

    firmenwagenAndererHersteller: {
      get: function () {
        if (this.firmenwagenHersteller === 'Anderer'
        && this.$parent.damagereport.fw_hersteller !== this.firmenwagenHersteller) {
          return this.$parent.damagereport.fw_hersteller;
        }

        return '';
      },

      set: function (newValue) {
        if (newValue.length) this.$parent.damagereport.fw_hersteller = newValue;
        else this.$parent.damagereport.fw_hersteller = 'Anderer';
      },
    },

    fahrerGeburtsDatum: {
      get: function () {
        const DateObject = moment(this.$parent.damagereport.fahrer_geburtsdatum);
        if (DateObject.format('YYYY') === '0001') return null;

        return DateObject.format('YYYY-MM-DD');
      },

      set: function (newValue) {
        if (!newValue.length) this.$parent.damagereport.fahrer_geburtsdatum = null;
        else this.$parent.damagereport.fahrer_geburtsdatum = moment(newValue, 'YYYY-MM-DD').utc().toISOString();
      },
    },

    kilometerStand: {
      get: function () {
        let km = this.$parent.damagereport.fw_kilometer_stand;
        km = `${km}`.replace(/[a-z\s.]/gi, '');
        km = parseInt(km, 10) || 0;

        return (km).toLocaleString('de-DE');
      },

      set: function (newValue) {
        this.$parent.damagereport.fw_kilometer_stand = newValue;
      },
    },

    formIsValid() {
      return this.$refs.form.validate() && this.validateForm();
    },

    ...mapGetters({
      jwt: 'user/jwt',
      showFahrzeugSuche: 'featureFlags/getFahrzeugSuche',
    }),
  },
  /* eslint-enable */

  beforeMount() {
    this.serverConfig.load.headers.Authorization = `Bearer ${this.jwt}`;
  },

  mounted() {
    this.getReport();
  },

  methods: {
    forceRerender() {
      this.herstellerKey += 1;
    },

    setFoundVehicle(e) {
      if (!e) return;
      const [kennzeichen1, kennzeichen2, kennzeichen3] = e.kfzkz.split('-');
      this.$parent.damagereport.fw_kennzeichen1 = kennzeichen1;
      this.$parent.damagereport.fw_kennzeichen2 = kennzeichen2;
      this.$parent.damagereport.fw_kennzeichen3 = kennzeichen3;
      if (!this.hersteller.includes(e.hersteller)) {
        this.firmenwagenHersteller = 'Anderer';
        this.firmenwagenAndererHersteller = e.hersteller;
      } else {
        this.$parent.damagereport.fw_hersteller = e.hersteller;
      }
      this.forceRerender();
      this.$parent.damagereport.fw_modell = e.typ;
    },

    getLocaleTimezone() {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (tz === 'undefined' || tz === '') {
        return 'Europe/Berlin';
      }
      return tz;
    },

    getReport() {
      this.httpResources.report.get({ id: this.id })
        .then((response) => {
          this.$parent.damagereport = response.body;
          const images = this.$parent.damagereport.bilder || [];
          images.forEach((item) => {
            this.formImages.push({
              source: `${item.id}`,
              options: { type: 'local' },
            });
          });
        })
        .catch((error) => { throw new Error(error); });
    },

    saveForm(complete) {
      this.formIsInvalidServer = false;
      return new Promise((resolve, reject) => {
        this.saving = true;
        this.validating = false;
        const km = parseInt(
          `${this.$parent.damagereport.fw_kilometer_stand}`.replace(/[a-z\s.]/gi, ''),
          10,
        ) || 0;
        this.$parent.damagereport.fw_kilometer_stand = km;

        // store timezone on first update
        const tz = this.$parent.damagereport.timezone;
        if (typeof tz === 'undefined' || tz === '') {
          this.$parent.damagereport.time_zone = this.getLocaleTimezone();
        }

        this.httpResources.report.update(this.$parent.damagereport)
          .then(() => {
            this.saving = false;
            resolve();
          })
          .catch((error) => {
            this.saving = false;
            if (complete !== false && error.status === 400) {
              this.$parent.damagereport.schadensmeldung_abgeschlossen = false;
              this.formIsInvalidServer = true;
              this.formIsInvalidServerRequestID = error.body.request_id;
              this.formIsInvalidServerValidationErrors = error.body.validation_errors;
            }
            reject();
          });
      });
    },

    validateForm() {
      let valid = true;
      const inputs = this.$refs.form.$el.querySelectorAll('[formrules]');
      inputs.forEach((input) => {
        const ref = input.getAttribute('data-ref');
        if (ref) {
          const $ref = this.$refs[ref];
          if ($ref.inputrules.length) {
            const notValidTest = $ref.inputrules.find((rule) => rule($ref.value) !== true);
            if (notValidTest) {
              $ref.error = notValidTest($ref.value);
              valid = false;
            } else $ref.error = null;
          }
        }
      });

      return valid;
    },

    completeForm() {
      this.validating = true;
      this.$nextTick(() => {
        if (this.formIsValid) {
          this.$parent.damagereport.schadensmeldung_abgeschlossen = true;
          this.saveForm(true)
            .then(() => this.$refs.dialogFormSuccess.openDialog())
            .catch(() => this.$refs.dialogFormFailure.openDialog());
        }
        document.location.hash = 'fa'; // anchor scroll area
      });
    },

    deleteForm() {
      this.deleting = true;
      this.$refs.dialogFormDelete.closeDialog();
      this.httpResources.report.delete({ id: this.$parent.damagereport.id })
        .then(() => {
          this.deleting = false;
          this.$router.push({ name: 'home' });
        })
        .catch(() => {
          this.deleting = false;
        });
    },
  },

  mixins: [
    httpResourcesMixin,
  ],
};
</script>

<style lang="scss">
@import "./../scss/settings.scss";

.filepond--item {
  width: 100%;
}

.v-input.v-text-field,
.v-input.v-textarea,
.input-wrapper {
  input,
  textarea,
  select {
    width: 100%;
    min-height: calc(2em + 16px);
    max-height: none;
    padding-top: 8px;
    padding-right: 2em;
    padding-bottom: 8px;
    padding-left: 10px;
    border: none;
    border-radius: 0;

    background: linear-gradient(315deg, transparent 12px, #fff 0) bottom right;

    line-height: 2;
    font-size: 16px;

    position: relative;
    z-index: 10;
  }

  textarea {
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    line-height: 1.5;
  }

  .v-input__slot {
    &::before,
    &::after {
      content: none;
      display: none;
    }
  }

  .v-text-field__slot {
    padding: 1px;
    margin-bottom: 0;

    position: relative;

    &::before {
      width: 100%;
      content: "";

      background: linear-gradient(315deg, transparent 12px, $clr-black-xlight 0)
        bottom right;

      display: block;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .v-text-field__details {
    display: none;
  }
}

.v-input.error--text {
  .v-text-field__slot {
    &::before {
      background: linear-gradient(315deg, transparent 12px, #cc3333 0) bottom
        right;
    }
  }

  .v-text-field__details {
    display: flex;
  }
}

.input-wrapper {
  padding: 1px;
  margin-bottom: 20px;

  position: relative;

  &::before {
    width: 100%;
    content: "";

    background: linear-gradient(315deg, transparent 12px, $clr-black-xlight 0)
      bottom right;

    display: block;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.select-wrapper {
  margin-bottom: 20px;

  .input-wrapper {
    margin-bottom: 8px;
  }
}

.has-error {
  .input-wrapper {
    &::before {
      background: linear-gradient(315deg, transparent 12px, $clr-red-base 0)
        bottom right;
    }
  }
}

fieldset {
  border: none;

  legend {
    width: 100%;
    margin-bottom: 20px;

    text-align: center;
    color: $clr-green-base;
    font-weight: bold;
    font-size: 18px;
  }
}

.v-text-field {
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 20px;
}

.radio-input {
  width: calc(2em + 22px);
  height: calc(2em + 22px);
  margin-top: 17px;
  margin-right: 20px;

  text-align: center;

  display: inline-block;

  position: relative;

  input {
    opacity: 0;
    position: absolute;
  }

  label {
    width: 100%;
    height: 100%;
    border: 1px solid $clr-black-xlight;

    text-align: center;

    display: inline-block;
    cursor: pointer;

    position: relative;

    span {
      text-align: center;

      position: absolute;
      top: 50%;
      right: 0;
      bottom: auto;
      left: 0;

      transform: translateY(-50%);
    }
  }

  input:checked + label {
    border-color: $clr-green-base;
    color: $clr-green-base;
  }
}

input:disabled,
select:disabled {
  color: #9c9c9c;
  -webkit-text-fill-color: #9c9c9c;
}
.v-input.v-text-field.v-input--is-disabled,
.v-input.v-textarea.v-input--is-disabled,
.input-wrapper {
  input:disabled,
  textarea:disabled,
  select:disabled {
    filter: opacity(1);
    opacity: 1;
    background: linear-gradient(315deg, transparent 12px, #fff 0) bottom right;
  }
}

.v-input--selection-controls.v-input--radio-group {
  padding-top: 0;
}

.error-box {
  width: 100%;

  padding-top: 10px;
  padding-right: 5px;
  padding-bottom: 10px;
  padding-left: 5px;
  border: 2px solid $clr-red-base;
  margin-top: 10px;
  margin-bottom: 10px;

  color: $clr-red-light;
  font-weight: bold;

  display: inline-block;
}

.required-mark {
  &::before {
    content: "*";

    font-size: 22px;
    // font-weight: bold;

    display: inline;

    position: relative;
    top: 3px;
    left: 2px;
  }
}
.required-mark.-hidden {
  visibility: hidden;
}

@media screen and (min-width: 601px) {
  .filepond--item {
    width: calc(50% - 0.5em);
  }
}
</style>

<style lang="scss" scoped>
@import "./../scss/settings.scss";

.damagereport {
  counter-reset: formSection;
}

.form-section {
  padding: 5px 10px;
  box-shadow: 0px 0.5px 3px 0px rgba(19, 0, 0, 0.5);
  margin-bottom: 30px;

  position: relative;

  &::after {
    counter-increment: formSection;
    content: counter(formSection) "/7";
    width: 40px;
    height: 40px;
    padding-top: 10px;

    // background-color: var(--clr-green-light);
    background: linear-gradient(315deg, transparent 10px, $clr-green-base 0)
      bottom right;
    color: #fff;
    font-weight: bold;
    text-align: center;

    display: block;

    position: absolute;
    top: 0;
    left: 0;

    transform: translate(-25%, -50%);
  }
}

.form-buttons .v-btn {
  width: 100%;
}
</style>
