<template>
    <v-dialog
      v-model="dialogIsOpen"
      max-width="280"
      :hide-overlay="false"
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          Schadensmeldung löschen?
        </v-card-title>
        <v-card-text>
          Möchten Sie diese Schadensmeldung wirklich löschen?
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="info"
            text
            @click="dialogIsOpen = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="error"
            @click="confirm"
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'DialogFormDelete',

  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      dialogIsOpen: this.open,
    };
  },

  methods: {
    openDialog() {
      this.dialogIsOpen = true;
    },
    closeDialog() {
      this.dialogIsOpen = false;
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>
