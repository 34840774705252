<template>
  <v-dialog
    v-model="dialogIsOpen"
    max-width="680"
    :hide-overlay="false"
  >
    <v-card>
      <v-card-title
        class="headline"
        primary-title
      >
        Fahrzeugsuche
      </v-card-title>
      <v-card-text>
        Finden Sie ein Fahrzeug anhand des Kennzeichens oder Ihren Nachnamens
        <v-tabs
          v-model="tab"
        >
          <v-tab>Name</v-tab>
          <v-tab>Kennzeichen</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-progress-circular
              v-if="loading && vehiclesByName.length===0"
              indeterminate
            ></v-progress-circular>
            <v-container v-else style="padding: 0;">
              <v-list two-line v-if="vehiclesByName.length>0">
                <v-list-item-group
                  v-model="selected"
                  active-class="pink--text"
                >
                  <template v-for="(item, index) in vehiclesByName">
                    <v-list-item :key="item.kfzkz" :value="item">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>{{item.kfzkz}}</v-list-item-title>
                          <v-list-item-subtitle
                            class="text--primary"
                          >{{item.hersteller}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-list-item>

                    <v-divider
                      v-if="index < vehiclesByName.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
              <p v-else style="margin-top: 15px">
                Eine Suche nach Ihrem Nachnamen "{{userName}}" ergab keine Treffer.
              </p>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-progress-circular
              v-if="loading && vehicleByKFZKZ.length===0"
              indeterminate
              color="green"
            ></v-progress-circular>
            <v-container v-else>
              <license-number v-model="localValue"
                              :show-label="false"
                              :space-for-dialog="true"></license-number>
              <v-btn
                @click.stop="searchByKFZ"
                color="info"
                style="width: 100%"
              >
                suchen
              </v-btn>
              <p v-if="searched && vehicleByKFZKZ.length===0" style="margin-top: 15px">
                Ihre Suche nach dem Kennzeichen
                "{{ value.kennzeichen1 }}-{{ value.kennzeichen2 }}-{{ value.kennzeichen3 }}"
                ergab keine Treffer.
              </p>
              <v-list two-line v-else>
                <v-list-item-group
                  v-model="selected"
                  active-class="pink--text"
                >
                  <template v-for="(item, index) in vehicleByKFZKZ">
                    <v-list-item :key="item.kfzkz" :value="item">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>{{item.kfzkz}}</v-list-item-title>
                          <v-list-item-subtitle
                            class="text--primary"
                          >{{item.hersteller}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-list-item>

                    <v-divider
                      v-if="index < vehicleByKFZKZ.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="info"
          text
          @click="dialogIsOpen = false"
          style="margin-right: 1em"
        >
          Abbrechen
        </v-btn>
        <v-btn
          color="success"
          @click="select"
        >
          Auswählen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpResourcesMixin from '@/mixins/httpResources';
import { mapGetters } from 'vuex';
import LicenseNumber from '@/components/LicenseNumber.vue';

export default {
  name: 'DialogFahrzeugsuche',
  components: { LicenseNumber },
  computed: {
    ...mapGetters({
      userName: 'user/name',
    }),
    localValue: {
      get() {
        return this.value;
      },
      set(localeValue) {
        this.$emit('input', localeValue);
      },
    },
  },

  created() {
    this.searchByName();
  },

  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      tab: null,
      dialogIsOpen: this.open,
      loading: false,
      searched: false,
      selected: [],
      vehicleByKFZKZ: [],
      vehiclesByName: [],
    };
  },
  methods: {
    openDialog() {
      this.dialogIsOpen = true;
    },
    closeDialog() {
      this.dialogIsOpen = false;
    },
    select() {
      if (this.selected.length > 0) {
        this.$emit('select', this.selected);
      }
      this.closeDialog();
    },
    searchByKFZ() {
      this.searched = true;
      if (!this.localValue) {
        return;
      }
      this.loading = true;
      const kfzkz = `${this.localValue.kennzeichen1}-${this.localValue.kennzeichen2}-${this.localValue.kennzeichen3}`;
      this.httpResources.search.save({ kfz: kfzkz })
        .then((response) => {
          this.vehicleByKFZKZ = response.body;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchByName() {
      if (!this.userName) {
        return;
      }
      this.loading = true;
      this.httpResources.search.save({ name: this.userName })
        .then((response) => {
          this.vehiclesByName = response.body;
        })
        .then(() => {
          this.sortByKFZKZ();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sortByKFZKZ() {
      this.vehiclesByName.sort((a, b) => a.kfzkz.localeCompare(b.kfzkz));
    },
  },
  mixins: [
    httpResourcesMixin,
  ],
};
</script>

<style lang="scss">
@import "./../scss/settings.scss";

.v-progress-circular {
  width: 100% !important;
  margin: 10px auto;
  color: $clr-green-base;
}

.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) >
.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
.v-slide-group__prev {
  display: none;
}
</style>
