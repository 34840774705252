<template>
    <v-dialog
      v-model="dialogIsOpen"
      max-width="280"
      attach="body"
      :hide-overlay="false"
      :persistent="true"
    >
      <v-card>
        <v-card-text>
          Ihre Schadensmeldung konnte nicht verschickt werden. Bitte prüfen Sie die Hinweise.
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            @click="dialogIsOpen = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'DialogFormFailure',

  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      dialogIsOpen: this.open,
    };
  },

  methods: {
    openDialog() {
      this.dialogIsOpen = true;
    },
    closeDialog() {
      this.dialogIsOpen = false;
    },
  },
};
</script>
