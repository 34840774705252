<template>
  <div
    :class="{'has-error': !!error && !!validating, 'select-wrapper': true}"
    formrules
  >
    <div class="input-wrapper">
      <label for="selectValue">
        <select
          id="selectValue"
          v-model="selectValue"
          :disabled="disabled"
          :validating="validating"
          @change="changeValue()"
        >
          <option v-for="(item, index) in options"
                  :value="item.hasOwnProperty('value') ? item.value : item"
                  :key="index"
          >
            {{ item.title || item }}
          </option>
        </select>
      </label>
    </div>
    <template v-if="error && validating">
      <p class="error-text">{{ error }}</p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'selectBox',

  inject: [
    'validateForm',
  ],

  props: {
    value: {
      type: String,
      required: true,
    },

    options: {
      type: Array,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    validating: {
      type: Boolean,
      required: false,
      default: false,
    },

    inputrules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      selectValue: this.value,
      error: null,
    };
  },

  methods: {
    /* eslint-disable no-param-reassign */
    changeValue() {
      this.$emit('input', this.selectValue);
      this.$nextTick(() => {
        if (this.validating) this.validateForm();
      });
    },
    /* eslint-enable */
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  position: relative;

  &::after {
    content: "";
    width: 12px;

    background-image: url('../assets/icons/dropdown.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;

    display: block;
    pointer-events: none;

    position: absolute;
    top: 0;
    right: 2em;
    bottom: 0;
    left: auto;
    z-index: 10;
  }

  select {
    cursor: pointer;
  }
}
</style>
