<template>
  <div
    :class="{'has-error': !!error && !!validating, 'input-radio': true}"
    formrules
  >
    <span class="group-title" v-if="label">
      {{ label }}<span v-if="required" class="required-mark" />
    </span>
    <div class="button-wrapper">
      <label
        v-for="(itemValue, key) in options"
        :key="key"
        :class="[(radioValue === itemValue) ? 'is-active' : '', 'radio-item']"
      >
        {{ key }}
        <input
          :value="key"
          :name="label"
          :disabled="disabled"
          type="radio"
          @click="changeValue($event, itemValue)"
        />
      </label>
    </div>
    <template v-if="error && validating">
      <p class="error-text">{{ error }}</p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'InputRadio',

  inject: [
    'validateForm',
  ],

  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },

    value: {
      required: true,
      default: null,
    },

    options: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    validating: {
      type: Boolean,
      required: false,
      default: false,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    inputrules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      radioValue: this.value,
      error: null,
    };
  },

  methods: {
    /* eslint-disable no-param-reassign */
    changeValue(event, value) {
      if (this.radioValue === value) {
        event.target.checked = false;
        this.radioValue = null;
      } else this.radioValue = value;

      this.$emit('input', this.radioValue);
      this.$nextTick(() => {
        if (this.validating) this.validateForm();
      });
    },
    /* eslint-enable */
  },
};
</script>

<style lang="scss" scoped>
@import './../scss/settings.scss';

.input-radio {
  margin-bottom: 20px;

  position: relative;
}

  .button-wrapper {
    display: inline-block;
  }

  .group-title {
    margin-right: 20px;
  }

  .radio-item {
    padding-top: 8px;
    padding-right: 10px;
    padding-bottom: 8px;
    padding-left: 10px;
    border: 1px solid $clr-black-xxlight;
    border-right: none;

    line-height: 1.5;
    text-align: center;

    cursor: pointer;
    display: inline-block;

    &:last-child {
      border-right: 1px solid $clr-black-xxlight;
    }
  }
  .radio-item.is-active {
    background-color: $clr-green-base;

    color: white;
  }

    input {
      visibility: hidden;
      position: absolute;
    }

    .error-text {
      padding-top: 8px;
      padding-bottom: 5px;
      font-size: 12px;
    }

  .has-error {
    .radio-item {
      border-color: $clr-red-base;
    }
  }
</style>
