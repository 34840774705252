import * as moment from 'moment';

const isFutureDate = (timestamp) => {
  const now = moment().unix();
  if (timestamp < now) return false;
  return true;
};

const lettersOnly = (value) => {
  const r = /^[a-zA-Z]+$/;
  return r.test(value);
};

const numbersOnly = (value) => {
  const r = /^[0-9]+$/;
  return r.test(value);
};

export default {
  schadensDatum: [
    (v) => {
      const inputDate = moment(`${v}`, 'YYYY-MM-DD').unix();
      if (!isFutureDate(inputDate)) return true;
      return 'Das Schadensdatum kann nicht in der Zukunft liegen.';
    },
  ],
  privatfahrt: [
    (v) => v !== null || 'Bitte geben Sie an, ob Sie privat oder dienstlich unterwegs waren.',
  ],
  unfallhergang: [
    (v) => !!v || 'Bitte beschreiben Sie, wie der Unfall geschehen ist.',
  ],
  schadensort: [
    (v) => !!v || 'Bitte geben Sie an, wo der Schaden stattgefunden hat oder bemerkt wurde.',
  ],
  selectHersteller: [
    (v) => (!!v && v.length > 0) || 'Bitte geben Sie einen Hersteller an.',
  ],
  andererHersteller: [
    (v) => !!v || 'Bitte geben Sie den Namen des Herstellers ein.',
  ],
  firmenwagenModell: [
    (v) => !!v || 'Bitte geben das Modell des Firmenwagens an.',
  ],
  erstzulassung: [
    (v) => !!v || 'Bitte geben Sie das Datum der Erstzulassung aus dem Fahrzeugschein an.',
    (v) => {
      const inputDate = moment(`${v}`, 'YYYY-MM-DD').unix();
      if (!isFutureDate(inputDate)) return true;
      return 'Die Erstzulassung kann nicht in der Zukunft liegen.';
    },
  ],
  kilometerStand: [
    (v) => !!v || 'Bitte geben Sie den aktuellen Kilometerstand Ihres Fahrzeugs ein.',
    (v) => (parseInt(v, 10) && parseInt(v, 10) > 0) || 'Bitte geben Sie den aktuellen Kilometerstand Ihres Fahrzeugs ein.',
  ],
  selberVerletzt: [
    (v) => v !== null || 'Bitte geben Sie an, ob Sie selbst verletzt wurden.',
  ],
  schaedenAmKfz: [
    (v) => !!v || 'Bitte beschreiben Sie den Schaden an Ihrem Fahrzeug.',
  ],
  besichtigungsOrt: [
    (v) => !!v || 'Bitte geben Sie an, wo das Fahrzeug besichtigt werden kann.',
  ],
  firma: [
    (v) => !!v || 'Bitte geben Sie an, bei welcher Firma das Fahrzeug besichtigt werden kann.',
  ],
  fahrerFuehrerschein: [
    (v) => v !== null || 'Bitte geben Sie an, ob Sie einen Führerschein besitzen.',
  ],
  fahrerAlkohol: [
    (v) => v !== null || 'Bitte geben Sie an, ob sie vor der Fahrt Alkohol oder andere Drogen konsumiert haben.',
  ],
  polizeiVerwarnung: [
    (v) => v !== null || 'Bitte geben Sie an, ob es eine gebührenpflichtige Verwarnung gab.',
  ],
  fahrerAnrede: [
    (v) => !!v || 'Bitte geben Sie die Anrede des Fahrers an.',
  ],
  fahrerVorname: [
    (v) => !!v || 'Bitte geben Sie den Vornamen des Fahrers an.',
  ],
  fahrerNachname: [
    (v) => !!v || 'Bitte geben Sie den Nachnamen des Fahrers an.',
  ],
  fahrerAdresse: [
    (v) => !!v || 'Bitte geben Sie die Adresse vom Fahrer an.',
  ],
  fahrerGeburtsDatum: [
    (v) => !!v || 'Bitte geben Sie das Geburtsdatum des Fahrers an.',
    (v) => !!(v && v.length) || 'Bitte geben Sie das Geburtsdatum des Fahrers an.',
    (v) => !(v && v.toLowerCase() === 'invalid datetime') || 'Bitte geben Sie das Geburtsdatum des Fahrers an.',
    (v) => {
      const inputDate = moment(`${v}`, 'YYYY-MM-DD').unix();
      if (!isFutureDate(inputDate)) return true;
      return 'Das Datum darf nicht in der Zukunft liegen.';
    },
  ],
  kennzeichen: [
    (v) => !!v || 'Bitte geben Sie das Kennzeichen Ihres Fahrzeugs an.',
    (v) => lettersOnly(v) || 'Achten Sie bitte darauf, dass Ihre Eingabe nur Buchstaben enthält. Leerzeichen und Sonderzeichen sind nicht erlaubt.',
    (v) => (v || '').length <= 3 || 'Ihre Eingabe scheint zu lang zu sein.',
  ],
  kennzeichenNummer: [
    (v) => !!v || 'Bitte geben Sie das Kennzeichen Ihres Fahrzeugs an.',
    (v) => numbersOnly(v) || 'Achten Sie bitte darauf, dass Ihre Eingabe nur Zahlen enthält. Leerzeichen und Sonderzeichen sind nicht erlaubt.',
    (v) => (v || '').length <= 4 || 'Ihre Eingabe scheint zu lang zu sein.',
  ],
  fremdschadenVorname: [
    (v) => !!v || 'Bitte geben Sie den Vornamen der geschädigten Person oder den Sachschaden an.',
  ],
  fremdschadenNachname: [
    (v) => !!v || 'Bitte geben Sie den Nachnamen der geschädigten Person oder den Sachschaden an.',
  ],
  fremdKennzeichen: [
    (v) => !!v || 'Bitte geben Sie das Kennzeichen des Unfallgegners an.',
  ],
  fremdschadenVersicherung: [
    (v) => (v || '').length <= 100 || 'Der Name der Versicherung darf nicht mehr als 100 Zeichen enthalten.',
  ],
  tagebuchNummer: [
    (v) => (v || '').length <= 50 || 'Die Tagebuchnummer darf nicht mehr als 50 Zeichen enthalten.',
  ],
};
