<template>
  <v-layout wrap class="gap-row">
    <v-flex xs8 md9>
      <label for="strasse">
        Straße<span v-if="required" class="required-mark" />
        <v-text-field
          id="strasse"
          v-model="localValue.strasse"
          :disabled="disabled"
          :rules="(validating) ? inputrules : []"
          placeholder="Musterstraße"
          @change="$emit('input', value)"
        ></v-text-field>
      </label>
    </v-flex>
    <v-flex xs4 md3>
      <label for="hausnummer">
        Hausnummer<span v-if="required" class="required-mark" />
        <v-text-field
          id="hausnummer"
          v-model="localValue.hausnummer"
          :disabled="disabled"
          :rules="(validating) ? inputrules : []"
          placeholder="17"
          inputmode="numeric"
          @change="$emit('input', value)"
        ></v-text-field>
      </label>
    </v-flex>
    <v-flex xs4 md3>
      <label for="plz">
        PLZ<span v-if="required" class="required-mark" />
        <v-text-field
          id="plz"
          v-model="localValue.plz"
          :disabled="disabled"
          :rules="(validating) ? inputrules : []"
          placeholder="12345"
          inputmode="decimal"
          @change="$emit('input', value)"
        ></v-text-field>
      </label>
    </v-flex>
    <v-flex xs8 md9>
      <label for="ort">
        Ort<span v-if="required" class="required-mark" />
        <v-text-field
          id="ort"
          v-model="localValue.ort"
          :disabled="disabled"
          :rules="(validating) ? inputrules : []"
          placeholder="Musterstadt"
          @change="$emit('input', value)"
        ></v-text-field>
      </label>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'InputAddress',

  props: {
    value: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    validating: {
      type: Boolean,
      required: false,
      default: false,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    inputrules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
