<template>
  <v-layout wrap class="gap-row">
    <v-flex :xs4="!spaceForDialog" :xs12="spaceForDialog">
      <label for="kennzeichen1">
        <span v-if="showLabel">Kennzeichen</span><span
          v-bind:class="{ 'required-mark': required, 'required-mark -hidden': !required }"
        />
        <v-text-field
          id="kennzeichen1"
          v-model="localValue.kennzeichen1"
          :disabled="disabled"
          :rules="setFormruleKennzeichen()"
          :class="{'remove-space': spaceForDialog}"
          placeholder="F"
          @change="$emit('input', value)"
        ></v-text-field>
      </label>
    </v-flex>
    <v-flex :xs4="!spaceForDialog" :xs12="spaceForDialog">
      <label for="kennzeichen2">
        &nbsp;<span
        class="required-mark -hidden"
      />
        <v-text-field
          id="kennzeichen2"
          v-model="localValue.kennzeichen2"
          :disabled="disabled"
          :rules="setFormruleKennzeichen()"
          :class="{'remove-space': spaceForDialog}"
          placeholder="WS"
          @change="$emit('input', value)"
        ></v-text-field>
      </label>
    </v-flex>
    <v-flex :xs4="!spaceForDialog" :xs12="spaceForDialog">
      <label for="kennzeichen3">
        &nbsp;<span
        class="required-mark -hidden"
      />
        <v-text-field
          id="kennzeichen3"
          v-model="localValue.kennzeichen3"
          :disabled="disabled"
          :rules="setFormruleKennzeichen(true)"
          placeholder="1234"
          @change="$emit('input', value)"
        ></v-text-field>
      </label>
    </v-flex>
  </v-layout>
</template>

<script>
import formrules from '@/data/formrules';

export default {
  name: 'LicenseNumber',

  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    validating: {
      type: Boolean,
      required: false,
      default: false,
    },
    fremdKennzeichen: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    spaceForDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      formrules,
      localeValue: this.value,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localeValue) {
        this.$emit('input', localeValue);
      },
    },
  },
  methods: {
    setFormruleKennzeichen(ruleForNumber = false) {
      if (!this.validating) {
        return [];
      }
      if (this.fremdKennzeichen) {
        return formrules.fremdKennzeichen;
      }
      if (ruleForNumber) {
        return formrules.kennzeichenNummer;
      }
      return formrules.kennzeichen;
    },
  },
};
</script>

<style lang="scss" scoped>
.remove-space {
  margin: 0;
}
</style>
